var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"page-container"},[_c('span',{staticClass:"heading"},[_vm._v("Select Round")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.getAllRounds),function(round){return _c('div',{key:round.id},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":+_vm.selectedRound === +round.name ? 'success' : '#3c474d'},on:{"click":function($event){return _vm.selectRound(round.name)}}},[_vm._v(" "+_vm._s(round.name)+" ")])],1)}),0),_c('span',{staticClass:"heading"},[_vm._v("Select User")]),_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.getAllUsers,"dense":"","label":"Users","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]}}],null,false,3465474716),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}),_c('span',{staticClass:"heading"},[_vm._v("Select Squad")]),_c('div',{staticClass:"squad-wrapper"},[_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.gk,"dense":"","item-text":"name","label":"GK","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.gk),callback:function ($$v) {_vm.$set(_vm.payload, "gk", $$v)},expression:"payload.gk"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dl,"dense":"","item-text":"name","label":"DL1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dl1),callback:function ($$v) {_vm.$set(_vm.payload, "dl1", $$v)},expression:"payload.dl1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dl,"dense":"","item-text":"name","label":"DL2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dl2),callback:function ($$v) {_vm.$set(_vm.payload, "dl2", $$v)},expression:"payload.dl2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dc,"dense":"","item-text":"name","label":"DC1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dc1),callback:function ($$v) {_vm.$set(_vm.payload, "dc1", $$v)},expression:"payload.dc1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dc,"dense":"","item-text":"name","label":"DC2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dc2),callback:function ($$v) {_vm.$set(_vm.payload, "dc2", $$v)},expression:"payload.dc2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dr,"dense":"","item-text":"name","label":"DR1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dr1),callback:function ($$v) {_vm.$set(_vm.payload, "dr1", $$v)},expression:"payload.dr1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.dr,"dense":"","item-text":"name","label":"DR2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.dr2),callback:function ($$v) {_vm.$set(_vm.payload, "dr2", $$v)},expression:"payload.dr2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.ml,"dense":"","item-text":"name","label":"ML1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.ml1),callback:function ($$v) {_vm.$set(_vm.payload, "ml1", $$v)},expression:"payload.ml1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.ml,"dense":"","item-text":"name","label":"ML2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.ml2),callback:function ($$v) {_vm.$set(_vm.payload, "ml2", $$v)},expression:"payload.ml2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.mc,"dense":"","item-text":"name","label":"MC1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.mc1),callback:function ($$v) {_vm.$set(_vm.payload, "mc1", $$v)},expression:"payload.mc1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.mc,"dense":"","item-text":"name","label":"MC2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.mc2),callback:function ($$v) {_vm.$set(_vm.payload, "mc2", $$v)},expression:"payload.mc2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.mr,"dense":"","item-text":"name","label":"MR1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.mr1),callback:function ($$v) {_vm.$set(_vm.payload, "mr1", $$v)},expression:"payload.mr1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.mr,"dense":"","item-text":"name","label":"MR2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.mr2),callback:function ($$v) {_vm.$set(_vm.payload, "mr2", $$v)},expression:"payload.mr2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.st,"dense":"","item-text":"name","label":"ST1","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.st1),callback:function ($$v) {_vm.$set(_vm.payload, "st1", $$v)},expression:"payload.st1"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.st,"dense":"","item-text":"name","label":"ST2","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.st2),callback:function ($$v) {_vm.$set(_vm.payload, "st2", $$v)},expression:"payload.st2"}}),_c('v-autocomplete',{staticClass:"player mt-2",attrs:{"items":_vm.st,"dense":"","item-text":"name","label":"ST3","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.st3),callback:function ($$v) {_vm.$set(_vm.payload, "st3", $$v)},expression:"payload.st3"}})],1),_c('div',{staticClass:"captains"},[_c('span',{staticClass:"heading"},[_vm._v("Select Captains")]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.squad,"dense":"","item-text":"name","label":"Captain","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.cpt),callback:function ($$v) {_vm.$set(_vm.payload, "cpt", $$v)},expression:"payload.cpt"}}),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.squad,"dense":"","item-text":"name","label":"Vice Captain","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.position))])]}}],null,false,4204111804),model:{value:(_vm.payload.vice_cpt),callback:function ($$v) {_vm.$set(_vm.payload, "vice_cpt", $$v)},expression:"payload.vice_cpt"}})],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"success"},on:{"click":_vm.submit}},[_vm._v(" SUBMIT TEAM ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }